import { UnleashClient } from 'unleash-proxy-client'

export const unleash = new UnleashClient({
  url: process.env.UNLEASH_PROXY_URL,
  clientKey: process.env.UNLEASH_CLIENT_KEY,
  appName: process.env.UNLEASH_APP_NAME,
})

// Start the background polling
// unleash.start()

import state from '~/apollo/state'

const AUTH_TYPE_TOKEN = 'token'
const AUTH_TYPE_CREDENTIALS = 'credentials'
const AUTH_TYPE_BIOMETRIC = 'biometric'

function redirectAfterLogin(userHomePage, onSuccess = null) {
  const { $router } = window.$nuxt
  let nextUrl = ''
  if (
    state.intendedUrl &&
    state.intendedUrl !== '' &&
    state.intendedUrl !== '/'
  ) {
    nextUrl = state.intendedUrl
    state.intendedUrl = null
  } else {
    nextUrl = userHomePage
  }

  $router.push(nextUrl, onSuccess)
}

function isPathProtected(path) {
  const isPatient = /^\/account\//.test(path)
  const isAdmin = /^\/admin/.test(path)
  const isProvider = /^\/provider/.test(path)
  return (
    isProvider ||
    isPatient ||
    (isAdmin && !/^\/admin\/login/.test(path) && !/^\/admin\/logout/.test(path))
  )
}

function canAccess(path, currentUser) {
  const userRole = currentUser ? currentUser.getRole : null
  const isPatient = /^\/account\//.test(path)
  const isAdmin = /^\/admin/.test(path)
  const isProvider = /^\/provider/.test(path)

  if (!userRole) return false

  if (isPatient) {
    return userRole === 'patient'
  }

  if (isAdmin) {
    return userRole === 'admin'
  }

  if (isProvider) {
    return !!currentUser.activeProviderOrganization
  }

  return false
}

export {
  AUTH_TYPE_BIOMETRIC,
  AUTH_TYPE_CREDENTIALS,
  AUTH_TYPE_TOKEN,
  canAccess,
  isPathProtected,
  redirectAfterLogin,
}

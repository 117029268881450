import Vue from 'vue'
import store from 'store'
import cloneDeep from 'lodash/cloneDeep'

const DEFAULT_VOIP_CALL_MODAL_STATUS = {
  isVisible: false,
  isMuted: false,
  isSpeakerOn: false,
  isQualityIssue: false,
  mode: null,
  name: null,
  uuid: null,
  state: null,
  timer: '00:00',
}
const DEFAULT_VOIP_PHONEBOOK_MODAL_STATUS = {
  isVisible: false,
}
const DEFAULT_VOIP_DIALER_MODAL_STATUS = {
  isVisible: false,
  phoneNumber: '',
  prevState: null,
}
const DEFAULT_VOIP_DIALPAD_MODAL_STATUS = {
  isVisible: false,
}
const DEFAULT_VOIP_STATUS = {
  updateTokenTries: 0,
  status: null,
  warnings: [],
}

const DEFAULT_UI = {
  loginModal: false,
  switchOrganizationModal: false,
  editPatientPhoneNumberModalPatientId: null,
  editPatientPhoneNumberModalProviderId: null,
  smsConfirmModal: false,
  insuranceModal: false,
  errorMessage: false,
  successMessage: false,
  insurancePicker: false,
  notifications: {},
  isLoading: false,
  hiddenNotifications: {},
  layoutNotifier: true,
  mobileMenu: false,
  enterpriseOnlyModal: false,
}

const state = Vue.observable({
  user: null,
  currentProvider: null,
  signup: null,
  intendedUrl: null,
  lastRoute: null,
  landingPage: null,
  utmCampaign: null,
  utmSource: null,
  utmContent: null,
  persistFilter: {},
  // Conversations
  conversations: [], // Stores the conversation objects
  conversationsShown: [], // Stores the conversation Ids
  maxConversations: 3,
  conversationHighlighted: null,
  appState: {},
  isAppLockShown: false,
  isBiometricAuthenticationRequested: false,
  // Draggrable Image
  isDraggrableImageActive: false,
  draggrableConversation: null,
  ui: cloneDeep(DEFAULT_UI),

  voipState: cloneDeep(DEFAULT_VOIP_STATUS),
  voipCallModal: cloneDeep(DEFAULT_VOIP_CALL_MODAL_STATUS),
  voipPhonebookModal: cloneDeep(DEFAULT_VOIP_PHONEBOOK_MODAL_STATUS),
  voipDialerModal: cloneDeep(DEFAULT_VOIP_DIALER_MODAL_STATUS),
  voipDialpadModal: cloneDeep(DEFAULT_VOIP_DIALPAD_MODAL_STATUS),
  isMobileSearchEnabled: false,
})

export { state as default }

// DO NOT CALL DIRECTLY, CALL SET_CURRENT_USER_MUTATION
export function setUser(user) {
  state.user = cloneDeep(user)
}
export function getUser() {
  return state.user
}
export function getProvider() {
  return state.currentProvider
}
// DO NOT CALL DIRECTLY, CALL SET_CURRENT_PROVIDER_MUTATION
export function setProvider(provider) {
  state.currentProvider = cloneDeep(provider)
}

export function getSignup() {
  return state.signup
}
export function setSignup(signup) {
  state.signup = signup
  store.set('signup', signup)
}
export function setLandingPage(landingPage) {
  if (landingPage === state.landingPage) return
  state.landingPage = landingPage
  store.set('landingPage', landingPage)
}
export function setUtmCampaign(utmCampaign) {
  if (utmCampaign === state.utmCampaign) return
  state.utmCampaign = utmCampaign
  store.set('utmCampaign', utmCampaign)
}
export function setUtmSource(utmSource) {
  if (utmSource === state.utmSource) return
  state.utmSource = utmSource
  store.set('utmSource', utmSource)
}
export function setUtmContent(utmContent) {
  if (utmContent === state.utmContent) return
  state.utmContent = utmContent
  store.set('utmContent', utmContent)
}
export function isLoggedIn() {
  return state.user && state.user.id
}
export function logout() {
  state.user = null
  state.currentProvider = null
  closeDraggrableImage()
  state.signup = null
  store.set('signup', null)

  state.ui = cloneDeep(DEFAULT_UI)
  store.set('ui', state.ui)
}
export function getHomePath({
  showLogin,
  redirect,
  tab,
  account,
  currentUser,
} = {}) {
  let userRole, user
  if (currentUser) {
    userRole = currentUser ? currentUser.getRole : null
    user = currentUser
  } else {
    userRole = state.user ? state.user.getRole : null
    user = state.user
  }
  switch (userRole) {
    case 'admin':
      return '/admin'
    case 'org_staff': {
      // Default to provider UI if they are hybrid accounts
      if (user.activeProviderOrganization) {
        const home = account
          ? '/provider/settings-and-notifications'
          : '/provider'
        const onboardingComplete = [
          'CLINICIAN_COMPLETE',
          'finished',
          'REHAB_COMPLETE',
        ]
        const provider = user.providers ? user.providers[0] : null
        if (
          provider &&
          provider.onboardingStep &&
          onboardingComplete.indexOf(provider.onboardingStep) === -1
        ) {
          return provider.providerType.name !== 'SUD Facility'
            ? `/signup/clinician?providerId=${provider.id}`
            : `/signup/sud?providerId=${provider.id}`
        } else {
          return home
        }
      }
    }
  }
  return `/${showLogin ? '#login' : ''}`
}
export function enableLayoutNotifier() {
  state.ui.layoutNotifier = true
}
export function disableLayoutNotifier() {
  state.ui.layoutNotifier = false
}
export function toggleNotification({ name, opts }) {
  const notifications = cloneDeep(state.ui.notifications)
  notifications[name] = opts
  state.ui.notifications = notifications
  if (opts && opts.persistent) {
    const storeUi = store.get('ui') || {}
    if (!storeUi.notifications) storeUi.notifications = {}
    storeUi.notifications[name] = opts
    store.set('ui', storeUi)
  }
}
export function closeNotification(name) {
  toggleNotification({ name: name || 'default', opts: null })
}
export function setNotification(opts) {
  let name = 'default'
  if (opts.name) {
    name = opts.name
    delete opts.name
  }
  toggleNotification({ name: name, opts: opts })
  if (!opts.persistent) {
    setTimeout(() => {
      toggleNotification({ name: name, opts: null })
    }, 5000)
  }
}
export function hideNotification(notificationName) {
  state.ui.hiddenNotifications[notificationName] = true
  const storeUi = store.get('ui') || {}
  storeUi.hiddenNotifications = state.ui.hiddenNotifications
  store.set('ui', storeUi)

  toggleNotification({ name: 'persistent-global', opts: null })
}
export function enableIsLoading(duration = 6000) {
  setIsLoading(true)
  setTimeout(() => {
    setIsLoading(false)
  }, duration)
}
export function setIsLoading(isLoading) {
  const newUi = Object.assign({}, state.ui)
  newUi.isLoading = isLoading
  state.ui = newUi
}
export function toggleLoginModal(isVisible) {
  const uiState = Object.assign({}, state.ui)
  uiState.loginModal = isVisible
  uiState.isModalOpen = isVisible
  state.ui = uiState
}
// Modal Window generic handler
export function toggleModal({ type, isVisible }) {
  const uiState = Object.assign({}, state.ui)
  uiState[`${type}Modal`] = isVisible
  uiState.isModalOpen = isVisible
  state.ui = uiState
}
/**
 * Toggle mobile menu
 * @param {Boolean} isVisible if the mobile menu is visible.
 */
export function toggleMobileMenu(isVisible) {
  const uiState = Object.assign({}, state.ui)
  uiState.mobileMenu = isVisible
  state.ui = uiState
}
export function toggleErrorMessage(message = '') {
  if (message === false) {
    state.ui.errorMessage = false
  } else {
    state.ui.errorMessage =
      message || 'Sorry, something went wrong. Please try again.'
  }
}
// Global UI Elements
export function toggleInsurancePicker(isVisible) {
  state.ui.insurancePicker = isVisible
  document
    .getElementsByTagName('body')
    .item(0)
    .classList.add('body-mobileStopScroll')
}

export function getConversationId(conv) {
  if (conv.type === 'PROVIDER') {
    return conv.providerId + conv.secondaryProviderId
  }
  return conv.patientId + conv.providerId
}

export function addConversation(conv) {
  if (!conv) return
  const conversationId = getConversationId(conv)
  const exists = state.conversations.find(
    (conversation) => getConversationId(conversation) === conversationId,
  )

  if (exists) return
  const conversations = [...state.conversations, conv]
  if (conversations.length > state.maxConversations) {
    conversations.splice(0, 1)
  }
  state.conversations = conversations
  showConversation(conv)
}

export function showConversation(conv) {
  if (!conv) return
  const exists = state.conversationsShown.find(
    (conversationId) => conversationId === getConversationId(conv),
  )

  if (exists) {
    highlightConversation(conv)
    setTimeout(() => {
      removeConversationHighlight()
    }, 100)
  } else {
    const conversationId = getConversationId(conv)
    const conversationIds = state.conversations.map((conversation) =>
      getConversationId(conversation),
    )
    const conversations = state.conversationsShown.filter((id) => {
      return conversationIds.indexOf(id) !== -1
    })
    state.conversationsShown = [...conversations, conversationId]
  }
}

export function hideConversation(conv) {
  if (!conv) return
  const exists = state.conversationsShown.find(
    (conversationId) => conversationId === getConversationId(conv),
  )

  if (!exists) return
  state.conversationsShown = state.conversationsShown.filter(
    (id) => id !== getConversationId(conv),
  )
}

export function removeConversation(conv) {
  if (!conv) return
  const conversationId = getConversationId(conv)
  hideConversation(conv)
  state.conversations = state.conversations.filter(
    (conversation) => getConversationId(conversation) !== conversationId,
  )
}

export function highlightConversation(conv) {
  state.conversationHighlighted = getConversationId(conv)
}

export function removeConversationHighlight() {
  state.conversationHighlighted = null
}

// Conversation Draggrable Preview

export function openDraggrableImage(conv) {
  state.isDraggrableImageActive = true
  state.draggrableConversation = conv
}

export function closeDraggrableImage() {
  state.isDraggrableImageActive = false
  state.draggrableConversation = null
}

// Edit patient modal
/**
 * Edit patient phone number modal
 * @param patientId {Number} the patient ID
 * @param providerId {Number} the provider ID
 */
export function editPatientPhoneNumber(patientId, providerId) {
  state.ui.editPatientPhoneNumberModalPatientId = patientId
  state.ui.editPatientPhoneNumberModalProviderId = providerId
}

export function toggleMobileSearchBar(isVisible) {
  state.isMobileSearchEnabled = isVisible
}

/**
 * Update Enterprise only modal status
 * @param status {Boolean} true when the enterprise menu is open, false otherwise
 */
export function updateEnterpriseOnlyModalStatus(status) {
  state.ui.enterpriseOnlyModal = status
}

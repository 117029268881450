import {
  ApolloClient,
  ApolloLink,
  Observable,
  split,
  InMemoryCache,
} from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { HttpLink } from 'apollo-link-http'
import PusherLink from 'graphql-ruby-client/subscriptions/PusherLink'
import { getMainDefinition } from 'apollo-utilities'
import { RetryLink } from '@apollo/client/link/retry'
import { onError } from '@apollo/client/link/error'
import { getPlatform } from '~/helpers/mobile-app'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'

import query from '~/apollo/resolvers/query'
import mutations from '~/apollo/resolvers/mutations'
import SET_TOKEN_MUTATION from '~/apollo/mutations/client/setToken'
import gql from 'graphql-tag'

if (process.env.NODE_ENV !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

// Define the initial data query
const INITIAL_DATA_QUERY = gql`
  query GetInitialData {
    currentUser
    currentProvider
    contacts
  }
`

const customFetch = (uri, options) => {
  return fetch(uri, options)
    .then((response) => {
      const refreshToken = response.headers.get('Refresh-Token')
      const refreshTokenExpiresAt = response.headers.get(
        'Refresh-Token-Expires-At',
      )
      if (refreshToken) {
        const token = { jwt: refreshToken, expiresAt: refreshTokenExpiresAt }
        client.mutate({
          mutation: SET_TOKEN_MUTATION,
          variables: { token },
        })
      }
      return response
    })
    .catch((e) => {
      if (e.message === 'Failed to fetch') {
        this.$router.push('/logout')
      }
    })
}

const batchHttpLink = new BatchHttpLink({
  uri: `${process.env.BACKEND_URL}graphql`,
  fetch: customFetch,
})

const errorLink = onError(({ response, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ type }) => {
      if (['unauthorized', 'token_format'].includes(type)) {
        localStorage.removeItem('wr-user-token')
        response.errors = undefined
        location.href = `/logout?reason=${type}`
      }
    })
  }
})

// wrap with a new link to get access to context.
// we need the context to authendicate the pusher client
const pusherLinkWithContext = new ApolloLink((operation, forward) => {
  // get the conxtext for the authorization header
  const context = operation.getContext()
  // instantiate the pusher client
  window.pusherClient.config.authEndpoint = process.env.PUSHER_AUTH_ENDPOINT
  window.pusherClient.config.auth = {
    headers: {
      Authorization: context.headers.authorization,
    },
  }
  const pusherLink = new PusherLink({ pusher: window.pusherClient })
  const httpLink = new HttpLink({
    uri: `${process.env.BACKEND_URL}graphql`,
    fetch: customFetch,
  })
  // The httpLink is a terminating link. The pusherLink is not
  // The pusher link subscribe to the next link in the chain
  // to get the response. In our case this will be the httpLink
  // After this it checks for the 'X-Subscription-ID'
  // in headers. If found it subscribe to this channel
  // https://github.com/rmosolgo/graphql-ruby/blob/master/javascript_client/subscriptions/PusherLink.js#L50
  // https://github.com/rmosolgo/graphql-ruby/blob/master/javascript_client/subscriptions/PusherLink.js#L70
  // So we need to combine these two links.
  // we don't want to use batching for subscriptions because we may have multiple requests
  // and we need multiple responses with 'X-Subscription-ID' in the header
  // and subscribe to these channels
  // Finally with this composition we get a terminating link
  // pusherLink -> httpLink
  const pusherWithErrorAndHttpLink = pusherLink.concat(
    errorLink.concat(httpLink),
  )
  // forward the operation to newly created link
  // Got it from: https://github.com/apollographql/apollo-link/blob/master/packages/apollo-link-batch-http/src/batchHttpLink.ts#L220
  return pusherWithErrorAndHttpLink.request(operation)
})

// we split two terminating links based on the operation
const linkWithPusher = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  pusherLinkWithContext,
  batchHttpLink,
)

const request = ({ setContext, getContext }) => {
  const headers = {
    'integration-source': process.env.ADMIN ? 'web-admin' : 'web',
  }
  if (process.env.CAPACITOR === 'true') {
    headers['integration-source'] = 'mobile'
    headers['platform'] = getPlatform()
  }

  const currentToken = JSON.parse(window.localStorage.getItem('wr-user-token'))
  const pathName = window.location.pathname

  if (!pathName.startsWith('/patient')) {
    if (currentToken) {
      headers.authorization = `Bearer ${currentToken.jwt}`
    }
  }

  const { headers: contextHeader } = getContext()
  setContext({
    headers: {
      ...headers,
      ...(contextHeader || {}),
    },
  })
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle = null
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    }),
)

const cache = new InMemoryCache()

// Define the initial data
const initialData = {
  currentUser: null,
  currentProvider: null,
  contacts: null,
}
cache.writeQuery({
  query: INITIAL_DATA_QUERY,
  data: initialData,
})

const retryLink = new RetryLink()
const link = ApolloLink.from([
  retryLink,
  requestLink,
  errorLink,
  linkWithPusher,
])

const defaultOptions = {
  query: {
    fetchPolicy: 'cache-and-network',
  },
}

const resolvers = {
  ...query,
  Mutation: {
    ...mutations,
  },
}

export const client = new ApolloClient({
  link,
  cache,
  resolvers,
  defaultHttpLink: false,
  connectToDevTools: process.env.NODE_ENV !== 'production',
  defaultOptions,
})
client.onResetStore(async () => {
  await cache.writeQuery({
    query: INITIAL_DATA_QUERY,
    data: initialData,
  })
  return true
})

export const purgeCache = async () => {
  localStorage.clear()
  // Reported issue
  // By waiting for all the observable queries to finish, there is no writing
  // on the store or cache, therefore the are not invariant violation errors
  // https://github.com/apollographql/apollo-client/issues/2919
  // https://github.com/apollographql/apollo-client/issues/3555
  await client.reFetchObservableQueries()
  await client.resetStore()
  return true
}

export default client
